<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Balance the reaction below in basic conditions. Note that basic solutions have
        <chemical-latex content="OH-" />
        available as a reactant or a product.
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="Br^-(aq) + MnO4^-(aq) -> MnO2(s) + BrO3^-(aq)" />
      </p>

      <chemical-notation-input
        v-model="inputs.balancedEquation"
        dense
        :show-note="false"
        class="mb-0"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';

export default {
  name: 'cpp1220Exam3Q6',
  components: {
    ChemicalNotationInput,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        balancedEquation: null,
      },
    };
  },
};
</script>
